import React, { useContext } from "react";
import AirsoftBanner from "../images/Heros/Airsoft-Hero.jpg";
import { Layout, EventContainer, Seo } from "../components";
import AirsoftHead from "../images/CardHeads/Airsoft-Head.png";
import BookingCardImg from "../images/BookingImgs/Booking-CardAS.jpg";
import ProductContext from "../context/ProductContext";

export default function AirsoftPage() {
	const jumboTexts = [
		<p>A high-intensity strategic combat game</p>,
		<p>Replica airsoft guns are used to shoot low-impact plastic BBs</p>,
		<p>Great for tactical scenarios</p>,
	];

	const FAQTexts = [
		{
			bodyText: [
				<h4>Certification Testing:</h4>,
				<p>
					All testing for Sniper, DMR, and LMG Certification will be done before
					matches at the chrono. Please show up early and have your gear ready
					for certification test.
				</p>,
			],
		},
		{
			bodyText: [
				<h4>What to wear: </h4>,
				<p>
					Eye protection is mandatory. It's a good idea to wear something to
					protect your face as well.
				</p>,
				<p>
					This is an outdoor action sport in the woods, so dress in weather
					appropriate active gear. BDU's or a hoodie and jeans, you do you.
				</p>,
				<p>
					It's a good idea for clothing to cover exposed skin and to wear good
					footwear that protects the ankle.
				</p>,
				<p>
					Airsoft BBs are lightweight and plastic, and they don't tend to mark
					or damage clothing.
				</p>,
			],
		},
		{
			bodyText: [
				<h4>What to bring:</h4>,
				<p>
					We provide rentals, but you're welcome to bring your own. Please make
					sure your airsoft gun will chronograph. Plenty of sports drinks and
					water are also a good idea.
				</p>,
			],
		},
		{
			bodyText: [
				<h4>What to expect:</h4>,
				<p>
					Yes, airsoft bb's can sting a little. The sensation is similar to
					getting snapped with a rubber band. However, players are usually
					having so much fun they hardly notice. Saftey glasses and mouth
					coverings must be worn at all times on the field. Overshooting and
					other forms of unsafe behavior are not permitted.
				</p>,
			],
		},
	];

	const { collections } = useContext(ProductContext);

	// Boiling collections array down to just paintball event tickets then sorting them by date created on Shopify.
	const airsoftEvents = collections
		.find((collection) => collection.title === "Airsoft")
		.products.filter((product) => product.productType === "Event Tickets")
		.sort((a, b) => {
			let dateA = new Date(a.createdAt),
				dateB = new Date(b.createdAt);
			return dateA - dateB;
		});

	const airsoftSpecials = collections
		.find((collection) => collection.title === "Airsoft Specials")
		.products.filter((product) => product.productType === "Event Tickets")
		.sort((a, b) => {
			let dateA = new Date(a.createdAt),
				dateB = new Date(b.createdAt);
			return dateA - dateB;
		});

	return (
		<>
			<Layout
				gameType='airsoft'
				heroImg={AirsoftBanner}
				page=''
				headerText='Nitehawk Airsoft'
				bodyText={jumboTexts}
				btnText=''
				btnRoute=''
				contentsDown='form'
				defaultSport='airsoft'
				FAQHeaderImg={AirsoftHead}
				FAQtexts={FAQTexts}
				BookingCardImg={BookingCardImg}
				altText='Airsoft Information'>
				<EventContainer
					headerText='Calendar Airsoft Events'
					events={airsoftEvents}
				/>
				<EventContainer
					headerText='Special Airsoft Events and Offers'
					events={airsoftSpecials}
				/>
				<Seo title='Airsoft' description='NiteHawk airsoft page' />
			</Layout>
		</>
	);
}
